/* TempTracker.css */
.temp-tracker-container {
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .temp-tracker-container h2 {
    text-align: center;
    color: #333;
  }
  
  .chart-container {
    margin-top: 20px;
  }
  .temp-tracker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .chart-container {
    width: 100%;
    height: 400px;
  }

  .top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%; /* Make the top navigation strip cover the entire width */
    background-color: #f9f9f9; /* Add background color */
    padding: 10px 20px; /* Add padding */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Add box shadow */
  }
  
  .logo-container {
    margin-right: 20px; /* Adjust spacing between logo and button */
  }
  
  .logo {
    width: 50px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  .home-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .home-button:hover {
    background-color: #0056b3;
  }