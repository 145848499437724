.add-order-container {
  flex-grow: 1; /* Allow container to fill the remaining space */
  padding: 20px;
  background-color: #fff; /* Set a clean white background */
  overflow: auto; /* Scroll as needed */
}

.order-row {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  align-items: center; /* Align items vertically */
  gap: 15px; /* Spacing between items */
  padding: 15px;
  margin-bottom: 10px; /* Spacing between rows */
  background: #f8f9fa; /* Slightly off-white background for contrast */
  border: 1px solid #e1e5eb; /* Light border for definition */
  border-radius: 6px; /* Slightly rounded corners for a modern feel */
}

.order-row > div {
  flex: 1; /* Allow each child to grow */
  min-width: 150px; /* Minimum width to prevent shrinking too much */
}

/* Improve the inputs and select element styling */
input[type="date"],
input[type="number"],
select,
textarea {
  width: 100%; /* Full width of their parent */
  padding: 10px; /* Comfortable padding */
  border: 1px solid #ced4da; /* Bootstrap-like input border */
  border-radius: .25rem; /* Bootstrap-like border radius */
  font-size: 1rem; /* Readable font size */
}

/* Style checkboxes with accompanying labels */
.order-row label {
  display: flex;
  align-items: center; /* Align the checkbox and label text */
  cursor: pointer; /* Indicate interactive element */
}

input[type="checkbox"] {
  margin-right: 5px; /* Spacing between checkbox and label text */
}

/* Quantity input next to checkboxes */
.quantity-input {
  max-width: 100px; /* Limit width of quantity inputs */
  margin-left: 15px; /* Space it from the checkbox */
  text-align: right; /* Align numbers to the right */
}

/* Style for the main heading */
.add-order-container h1 {
  color: #212529; /* Bootstrap-like dark color */
  font-size: 1.75rem; /* Slightly larger heading */
  font-weight: 500; /* Medium font weight */
  margin-bottom: 1rem; /* Bootstrap-like spacing */
}

/* Buttons styling for a professional look */
button {
  padding: .375rem .75rem; /* Bootstrap-like padding */
  border: 1px solid transparent; /* Prepare for focus state */
  line-height: 1.5; /* Bootstrap-like line height */
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out; /* Smooth transition */
}

/* Specific styling for Remove button */
.remove-button {
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text */
  border-color: #f5c6cb; /* Light red border */
}

.remove-button:hover {
  background-color: #f5c6cb; /* Slightly darker on hover */
}

/* Media queries for responsive adjustments */
@media (max-width: 768px) {
  .order-row {
    flex-direction: column; /* Stack elements vertically on small screens */
  }
}
.header {
  display: flex;
  align-items: center;
  background-color: #005792; /* Adjust the color to match your theme */
  color: white;
  padding: 10px 20px;
}

.logo {
  height: 50px; /* Adjust the size as needed */
  margin-right: 20px;
}

.header-nav {
  margin-left: auto; /* Push the navigation to the right */
}

.header-nav button {
  margin-left: 20px; /* Space out the buttons */
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.header-nav button:hover {
  text-decoration: underline; /* Indicate interactivity */
}
