/* Overall Layout Enhancements */
.orders-page {
  display: grid;
  grid-template-columns: 250px auto;
  gap: 20px;
  max-width: 1200px;
  margin: 40px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #ffffff;
  color: #333;
}

.orders-sidebar {
  background-color: #fafafa;
  padding: 20px;
  border-right: 2px solid #f0f0f0;
}

.orders-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.orders-sidebar ul li {
  padding: 15px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.orders-sidebar ul li:hover,
.orders-sidebar ul li.active {
  background-color: #0056b3;
  color: white;
}

/* Invoice Item Enhancements */
.orders-content {
  padding: 20px;
}

.invoice-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.invoice-item:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.order-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-detail {
  font-size: 16px;
}

.order-detail-title {
  font-weight: 600;
}

/* Typography & Colors Update */
body {
  background-color: #f4f4f4;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
}

h2 {
  color: #0056b3;
}

/* Grand Total Enhancements */
.grand-total {
  font-size: 20px;
  text-align: right;
  font-weight: bold;
  border-top: 2px solid #ddd;
  padding-top: 10px;
  margin-top: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .orders-page {
    grid-template-columns: 1fr;
  }

  .orders-sidebar {
    border-right: none;
    border-bottom: 2px solid #f0f0f0;
  }
}
