/* Base styles */
body, button {
    font-family: 'Arial', sans-serif;

  }
  
  /* Wrapper for the entire page */
  .signin-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height to fill the screen */
    padding: 20px;
    background-color: #ecf0f1; /* Light grey background */
    background: url('/src/background.png') no-repeat center center fixed; 
  background-size: cover;
  }
  
  /* Container for sign-in */
  .signin-container {
    background-color: #ffffff; /* White background */
    padding: 40px;
    border-radius: 8px; /* Rounded corners for the container */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-right: 20px; /* Spacing between sign-in and sign-up sections */
  }
  
  .logo-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .logo {
    max-width: 100px; /* Adjust the size of your logo */
    margin-bottom: 20px;
  }
  
  .login-header, .signup-header {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .social-login {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .social-button {
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    color: white;
    width: 40px; /* Fixed size for circles */
    height: 40px; /* Fixed size for circles */
  }
  
  .facebook { background-color: #3b5998; }
  .google { background-color: #dd4b39; }
  .linkedin { background-color: #0077b5; }
  
  .divider {
    text-align: center;
    margin-bottom: 20px;
    color: #999;
  }
  
  .login-form input {
    width: calc(100% - 20px); /* Full width minus padding */
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  .signin-button {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #3498db; /* Blue button */
    color: white;
    cursor: pointer;
  }
  
  /* Container for sign-up */
  .signup-section {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .signup-text {
    margin-bottom: 20px;
  }
  
  .signup-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #3498db; /* Blue button to match sign-in */
    color: white;
    cursor: pointer;
  }

  .cover-image-container {
    position: relative;
    width: 100%; /* or the width of the area you want to cover */
    height: 300px; /* or the height you want */
  }
  
  .cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will ensure the image covers the entire area */
  }
  .signin-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Arial', sans-serif;
    position: relative; /* Make sure your wrapper is relative for absolute positioning of children */
  }
  
  .cover-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* or whatever height you prefer */
    z-index: -1; /* Ensure it's behind all other content */
  }
  
  .cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the whole area */
  }
  
  /* ...rest of your CSS */
    
  