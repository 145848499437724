/* EditOrder.css */
.edit-order-page {
  display: flex;
}

.aside-nav {
  flex: 1;
  max-width: 200px;
  background-color: #f0f0f0;
  padding: 20px;
}

.edit-order-container {
  flex: 3;
  padding: 20px;
}

.orders-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 500px;
  overflow-y: auto;
}

.order-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.aside-nav ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.aside-nav ul li.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.sidebar-logo {
  width: 100%;
  max-width: 180px;
  margin-bottom: 20px;
  display: block;
}

.sidebar-logo + ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.sidebar-logo + ul li:hover {
  background-color: #007bff;
  color: white;
}

.input-disabled {
 
  cursor: not-allowed;
}

.slide-text {
  overflow: hidden;
  white-space: nowrap;
  animation: slide 20s linear infinite; /* Adjust duration and timing function as needed */
}

@keyframes slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
