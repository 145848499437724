/* General Styling */
.business-info-container {
  display: flex;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: #fff;
  border-right: none;
  height: 100%;
  overflow-y: auto;
}

.logo {
  width: 100%; /* Make the logo responsive */
  max-width: 150px; /* Adjust based on your logo size and sidebar width */
  margin-bottom: 20px; /* Add some space between the logo and the navigation items */
  display: block; /* Ensure the logo takes up the entire line */
}

.nav ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.nav-item {
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-item:hover,
.nav-item.active {
  background-color: #3498db;
}

.content {
  flex-grow: 1;
  background-color: #ecf0f1;
  padding: 40px;
}

/* Form Styling */
.business-form {
  max-width: 600px;
  margin: 0 auto;
}

.business-form label {
  margin-bottom: 10px;
  font-weight: bold;
}

.business-form input,
.business-form select,
.business-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.business-form button {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.business-form button:hover {
  background-color: #2980b9;
}

/* Business Information Tab Styling */
.business-info-tab {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Add some space between tabs */
}

.business-info-tab h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.business-info-tab label {
  margin-bottom: 10px;
  font-weight: bold;
}

.business-info-tab input,
.business-info-tab select,
.business-info-tab textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.business-info-tab button {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.business-info-tab button:hover {
  background-color: #2980b9;
}

/* Additional Styles for Invoice Details */
.invoice-details-container {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.invoice-details-container label {
  font-weight: bold;
}

.invoice-details-container input[type="text"],
.invoice-details-container input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Responsive Design */
@media (max-width: 768px) {
  .business-info-tab {
    padding: 10px;
  }
}

.form-group {
  display: flex;
  align-items: center;
}

.invoice-details-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.invoice-details-title {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.invoice-details-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 5px;
  color: #555;
}

.form-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}

.form-input:focus {
  border-color: #5b9bd5;
  outline: none;
}

.form-submit-btn {
  padding: 10px 15px;
  background-color: #5b9bd5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.form-submit-btn:hover {
  background-color: #2980b9;
}
