.orders-page {
  display: flex;
  font-family: Arial, sans-serif;
}

.orders-sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
}

.orders-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.orders-sidebar ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.orders-sidebar ul li.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.orders-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  border-left: 1px solid #ddd;
}
.sidebar-logo {
  width: 100%; /* Make the logo responsive */
  max-width: 180px; /* Adjust based on your logo size and sidebar width */
  margin-bottom: 20px; /* Add some space between the logo and the navigation items */
  display: block; /* Ensure the logo takes up the entire line */
}

.cart-items-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between items */
}

.cart-item {
  display: flex;
  align-items: center;
  gap: 15px; /* Spacing between icon and details */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.cart-icon {
  font-size: 24px;
  color: #007bff;
}

.order-details {
  display: flex;
  flex-direction: column;
}

.order-detail {
  margin: 5px 0; /* Spacing for each order detail */
}

.order-detail svg {
  margin-right: 5px; /* Spacing between icon and text */
  vertical-align: middle;
}

.under-construction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh; /* Adjust based on your preference */
  text-align: center;
}

.under-construction img {
  max-width: 60%; /* Adjust based on your preference */
  height: auto;
  margin-bottom: 20px;
}
.aside-nav ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.aside-nav ul li.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.sidebar-logo {
  width: 100%;
  max-width: 180px;
  margin-bottom: 20px;
  display: block;
}

.sidebar-logo + ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.sidebar-logo + ul li:hover {
  background-color: #007bff;
  color: white;
}
