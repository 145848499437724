.signup-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Arial', sans-serif;
}

.signup-container {
  display: flex;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
  z-index: 10;
}

.signup-image {
  flex: 1;
}

.signup-side-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.signup-form-container {
  padding: 32px;
  flex-basis: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signup-logo {
  /* Adjust as per your logo's aspect ratio */
  max-width: 50%; /* or a fixed width, e.g., 200px */
  max-height: 50px; /* Adjust the height as well */
  object-fit: contain; /* This will ensure the logo keeps its aspect ratio */
  margin-bottom: -30px;
  background: #fff; /* Or another color that contrasts well with your logo */
  padding: 10px; /* Adjust or remove padding as needed */
}


h2 {
  margin-bottom: 8px;
}

.already-signed-up {
  margin-bottom: 16px;
}

.signup-form {
  width: 100%;
}

.signup-form input,
.signup-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.registered-organizations {
  /* Placeholder until options are populated */
}

.signup-button {
  width: 100%;
  padding: 16px;
  border: none;
  background-color: #22a6b3; /* Replace with your brand color */
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
}

.or {
  text-align: center;
  width: 100%;
}

.signup-button:hover {
  background-color: #199a9f; /* A shade darker for hover effect */
}

a {
  color: #0077b5;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* ...rest of your CSS */

.password-container {
position: relative;
}

.faEye, .faEyeSlash {
position: absolute;
right: 10px;
top: 10px;
cursor: pointer;
}

.password-error {
color: red;
font-size: 0.8em;
}

/* Enhances visibility for success and error messages */
.signup-success, .signup-error {
  font-size: 1.2em; /* Larger font size */
  font-weight: bold; /* Bold text for better visibility */
  color: #fff; /* White text for high contrast */
  background-color: #4CAF50; /* Green background for success */
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  box-shadow: 0px 0px 5px #000; /* Optional: Adds shadow for depth */
}

.signup-error {
  background-color: #f44336; /* Red background for errors */
}

/* Ensures input placeholder and text are easily readable */
.signup-form input, .signup-form select {
  color: #000; /* Dark text for readability */
  background-color: #fff; /* Light background to ensure contrast */
  /* Rest of the styles remain unchanged */
}

/* Enhances the visibility of hyperlinks against backgrounds */
a, a:hover {
  color: #ffd700; /* Bright color for links */
  text-decoration: underline; /* Underline for emphasis */
  /* Adjust hover styles as necessary */
}

/* Additional styles to ensure visibility of all form elements */
.signup-form-container {
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background to help text stand out */
  border-radius: 8px; /* Matches container's border-radius */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

/* Adjust the .password-container for visibility of icons */
.password-container {
  background-color: #fff; /* Ensures icons are visible against a light background */
  /* Keep the rest of the styles unchanged */
}
