/* Home.css */

.dashboard-container {
  font-family: 'Arial', sans-serif;
 
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center vertically */
  padding: 10px;
  background-color: #005792;
  color: white;
}

.logo-container {
  display: flex; /* Align logo and title horizontally */
  align-items: center; /* Center vertically */
  padding-bottom: -30px;
}

.logo {
  width: 20px; /* Adjust size as needed */
  height: auto;
  margin-right: 10px; /* Add space between logo and title */
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
}

.title {
  margin: 0; /* Remove default margin */
}

.dashboard-content {
  padding: 10px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.dashboard-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #0090D6;
  border-radius: 5px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative; /* Updated for positioning the development icon */
}

.dashboard-item:hover {
  transform: translateY(-5px);
}

.dashboard-icon {
  margin-bottom: 10px;
  color: white;
}

/* Styles for under development features */
.under-development {
  opacity: 0.5; /* Makes the content semi-transparent */
  background-color: #f0f0f0; /* Light grey background */
  color: #a0a0a0; /* Greyed out text */
  cursor: default; /* Resets cursor to default instead of pointer */
  position: relative; /* Position relative for the ::after pseudo-element positioning */
}

.under-development::after {
  content: '⚡'; /* Unicode for a lightning bolt symbol */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: yellow; /* Lightning bolt color */
  pointer-events: none; /* Prevents the pseudo-element from being clickable */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
}

/* Additional styles for visibility adjustments */
.dashboard-item p {
  z-index: 2; /* Ensure the text is above the pseudo-element */
  position: relative;
}

/* Add a new CSS class for greyed out dashboard items */
.disabled-item {
  background-color: #808080; /* Grey background color */
  cursor: default; /* Change cursor to default */
}

/* Add pseudo-element styles for disabled items */
.disabled-item::after {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #808080; /* Uniform grey background for items */
  border-radius: 5px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly darker shadow for depth */
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative; /* Updated for positioning the development icon */
}
/* Styles specific to under-development tabs */
.under-development {
  background-color: #A9A9A9; /* Medium grey background */
  color: #FFFFFF; /* White text */
}

.under-development .dashboard-icon {
  color: #D3D3D3; /* Light grey icon */
}

/* Adjust hover effect for under-development items */
.under-development:hover {
  background-color: #A9A9A9; /* Keep the background color on hover */
  transform: none; /* No moving up effect */
  cursor: not-allowed; /* Show that the item is not interactive */
}
/* Make sure the sign out button and other interactive elements match the grey theme */
.signout-button {
  background-color: #D3D3D3; /* Light grey background */
  color: #333; /* Dark grey text */
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Icons for active items */
.dashboard-item:not(.under-development) .dashboard-icon {
  color: #333; /* Dark grey icons for contrast */
}

.dashboard-item:not(.under-development):hover {
  background-color: #E0E0E0; /* Slightly darker grey background on hover */
}
/* background.css */


